
/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, (attr) => {
        $(`*[name=${attr}]`, parentSelector)
            .addClass('is-invalid');

        $(`*[name=${attr}]`, parentSelector).closest('.input-row')
            .addClass('is-invalid')
            .find('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find('.is-invalid').removeClass('is-invalid');
    $(parentSelector).find('.invalid-feedback').html('');
    $('.error-message').hide();
}

module.exports = {
    loadFormErrors,
    clearPreviousErrors,
};
