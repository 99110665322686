import { pushCheckoutErrors } from './../gtm/checkoutFlow';
import { updatePaymentScheduleOptions } from './paymentMethodHelpers';
import { scrollLock } from '../util';

const LAPTOP_MAX_WIDTH = 1025;
const LOW_ENTRY_BARRIER_MESSAGE_ID = 'low-entry-barrier-message';

/**
* Re-binds summary accordions
*/
function initSummaryAccordion() {
    $('body').on('click', '.js-acc-head', (e) => {
        $(e.target).toggleClass('content-open');
    });
}

/**
* Initializes checkout step 5 order summary accordions
*/
function initStep5SummaryAccordion() {
    $('.js-payment-summary-new .js-acc-head').each(function bindClickEvent() {
        $(this).off().on('click', () => {
            $(this).toggleClass('content-open');
        });
    });
}

/**
* Shows one of 3 order summaries based on the current checkout step
* @param {number} step - Checkout step
*/
function showSummary(step) {
    if (step === 3) {
        $('.js-checkout-order-summary-step01').hide();
        $('.js-checkout-order-summary-step02').hide();
        $('.js-checkout-order-summary').show();
    } else if (step === 2) {
        $('.js-checkout-order-summary').hide();
        $('.js-checkout-order-summary-step01').hide();
        $('.js-checkout-order-summary-step02').show();
    } else if (step === 1) {
        $('.js-checkout-order-summary').hide();
        $('.js-checkout-order-summary-step02').hide();
        $('.js-checkout-order-summary-step01').show();
    }
}

/**
* Opens/closes mobile order summary
*/
function toggleMobileSummary() {
    $('body').on('click', '.summary-veil', () => {
        // .menu-close
        $('.checkout-order-summary-floating').removeClass('menu-open');
        scrollLock('off');
        $('.checkout-order-summary-floating .summary-body').slideToggle();
        $('body').removeClass('no-scroll fixed');
        $('.summary-veil').removeClass('active');
    });

    $('body').on('click', '.checkout-order-summary-floating .summary-header', () => {
        $('body').toggleClass('no-scroll');
        if ($('body').hasClass('no-scroll')) {
            scrollLock('on');
        } else {
            scrollLock('off');
        }
        $('.summary-veil').toggleClass('active');
        $('.checkout-order-summary-floating').toggleClass('menu-open');
        $('.checkout-order-summary-floating .summary-body').slideToggle();
    });
}

/**
* Closes mobile order summary
*/
function closeMobileSummary() {
    const $floatingSummary = $('.checkout-order-summary-floating');
    const $floatingSummaryHeader = $('.checkout-order-summary-floating .summary-header');
    if ($floatingSummary.hasClass('menu-open')) {
        $floatingSummaryHeader.trigger('click');
    }
}

/**
* Hides floating order summary when inline is visible
*/
function mobileSummaryInlineInview() {
    const inlineSum = document.querySelector('.checkout-order-summary-static');

    if (inlineSum) {
        const rect = inlineSum.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const vertInView = (rect.top < windowHeight) && ((rect.top + rect.height) > 0);

        if (vertInView) {
            $('.checkout-order-summary-floating').addClass('summary-hidden');
        } else {
            $('.checkout-order-summary-floating').removeClass('summary-hidden');
        }
    }
}

/**
* Binds click event to toggle order summary
*/
const bindOrderSummaryToggleTouchDevices = () => {
    if ($(window).innerWidth() < LAPTOP_MAX_WIDTH) {
        $('.checkout-navigation > .js-checkout-order-summary .js-order-summary-btn, .js-order-close-btn').on('click', () => {
            $('.js-order-summary-btn').toggleClass('active');
            $('.js-order-summary').toggleClass('show');
        });
    }
};

/**
* Binds click event to open low entry barrier promotion pop-up
*/
const bindOrderSummaryLowEntryBarrierPopup = () => {
    $('body').on('click', '.js-low-entry-barrier-popup', () => {
        const $lowEntryBarrierPlanMessage = $(`.js-plan-message[data-plan-message-id="${LOW_ENTRY_BARRIER_MESSAGE_ID}"]`);
        const $tooltip = $lowEntryBarrierPlanMessage.find('.js-tooltip-modal-icon');
        if ($tooltip.length > 0) {
            $tooltip.trigger('click');
        }
    });

    // Since this item is clicked from other locations, it should close the MobileSummary when applicable
    $('body').on('click', '.js-tooltip-modal-icon', () => {
        closeMobileSummary();
    });
};

/**
* (Re)binds checkout step 5 click events to open low entry barrier promotion pop-up
*/
function initStep5bindOrderSummaryLowEntryBarrierPopup() {
    const $lowEntryBarrierPlanMessage = $(`.checkout-order-summary-floating .js-plan-message[data-plan-message-id="${LOW_ENTRY_BARRIER_MESSAGE_ID}"]`);
    const $tooltip = $lowEntryBarrierPlanMessage.find('.js-tooltip-modal-icon');

    const $injectedPopupLink = $('.js-payment-summary-new .js-low-entry-barrier-popup');
    const $injectedTooltip = $(`.js-payment-summary-new .js-plan-message[data-plan-message-id="${LOW_ENTRY_BARRIER_MESSAGE_ID}"] .js-tooltip-modal-icon`);

    $injectedPopupLink.add($injectedTooltip).each(function bindClickEvent() {
        $(this).off('click').on('click', () => {
            if ($tooltip.length > 0) {
                $tooltip.trigger('click');
            }
        });
    });
}

/**
* Toggles visibility of the one cent promotion content
* @param {boolean} isOneCentPromotion - True if the one cent promotion is active, False otherwise
*/
const toggleOneCentPromotionContent = isOneCentPromotion => {
    const $oneCentPromotionContractDisclaimer = $('.js-one-cent-promotion-contract-disclaimer');
    const $4Weeks = $('.js-per-4-weeks-asset');
    const $oneCentPromotionAsset = $('.js-one-cent-promotion-asset');

    if (isOneCentPromotion) {
        $4Weeks.hide();
        $oneCentPromotionAsset.show();
        $oneCentPromotionContractDisclaimer.show();
    } else {
        $4Weeks.show();
        $oneCentPromotionAsset.hide();
        $oneCentPromotionContractDisclaimer.hide();
    }
};

/**
 * Updates order summary
 */
const updateMiniCart = () => {
    const url = $('.update-mini-cart').val();
    const $orderSummary = $('.js-checkout-order-summary');
    const $paymentSummaryNew = $('.js-payment-summary-new');
    const isFirstCheckoutStep = $('.js-choose-step-tab[data-stage="HomeClub"]').hasClass('active');

    $.ajax({
        url,
        method: 'GET',
        success: data => {
            const {
                error,
                errorMessage,
                miniCartHtml,
                isOneCentPromotion,
            } = data;

            if (error) {
                pushCheckoutErrors('checkout', errorMessage || error.errorText);
                return;
            }

            $orderSummary.empty().append(miniCartHtml);
            sessionStorage.setItem('miniCartTemplate', miniCartHtml);
            $paymentSummaryNew.empty().append($orderSummary
                .clone()
                .removeClass('js-checkout-order-summary checkout-order-summary-floating')
                .addClass('checkout-order-summary-static'));

            initStep5SummaryAccordion();
            initStep5bindOrderSummaryLowEntryBarrierPopup();
            updatePaymentScheduleOptions(data);
            toggleOneCentPromotionContent(isOneCentPromotion);

            if (!isFirstCheckoutStep) {
                bindOrderSummaryToggleTouchDevices();
                showSummary(3);
            }

            $('.js-checkout-order-summary').css('pointer-events', 'auto');
        },
        error: () => {
            $.spinner().stop();
        },
    });
};

export {
    initSummaryAccordion,
    initStep5SummaryAccordion,
    initStep5bindOrderSummaryLowEntryBarrierPopup,
    showSummary,
    toggleMobileSummary,
    mobileSummaryInlineInview,
    bindOrderSummaryToggleTouchDevices,
    bindOrderSummaryLowEntryBarrierPopup,
    updateMiniCart,
};
