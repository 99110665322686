import adyenDropin from './adyenDropin';
import { initStep5SummaryAccordion, initStep5bindOrderSummaryLowEntryBarrierPopup } from './orderSummary';
import { toggleOneCentPromotionContent } from './paymentMethodHelpers';
import {
    pushSelectedAddOnData,
    pushRemovedProductFromCart,
    pushCheckoutErrors,
} from './../gtm/checkoutFlow';

let ibanInputs = $('.js-acount-inputs.visually-hidden').detach();
let sepaCheckbox = $('.js-sepa-payment-checkbox.visually-hidden').detach();

export default function payementInitialize() {
    $('.js-payment-schedule').on('click', e => {
        const $target = $(e.currentTarget);
        const $wrapper = $target.closest('[data-url]');
        $wrapper.find('.js-payment-interval-error').addClass('visually-hidden');
        const paymentInterval = $target.find('input').val();
        const url = $wrapper.attr('data-url');
        const endpointUrl = `${url}?paymentInterval=${paymentInterval}`;

        $.ajax({
            url: endpointUrl,
            type: 'POST',
            success: ({
                isSepaYearPlanAllowed,
                error,
                errorMessage,
                gtmProductsNew,
                gtmRemovedProducts,
                cart,
                subscriptionBasePrice,
                allRecurringCosts,
                addOnsHtml,
                extraYearlyPaymentPromo,
                miniCartHtml,
                isOneCentPromotion,
                adyenSessionData,
            }) => {
                if (!isSepaYearPlanAllowed && paymentInterval === '52weeks' && $('.js-no-sepa-message').length) {
                    ibanInputs = $('.js-acount-inputs').detach();
                    sepaCheckbox = $('.js-sepa-payment-checkbox').detach();
                    $('.js-skip-sepa').val(true);
                    $('.js-no-sepa-message').removeClass('visually-hidden');
                } else {
                    $('.js-no-sepa-message').after(ibanInputs);
                    $('.checkboxes').prepend(sepaCheckbox);
                    $('.js-skip-sepa').val(false);
                    $('.js-acount-inputs').removeClass('visually-hidden');
                    $('.js-sepa-payment-checkbox').removeClass('visually-hidden');
                    $('.js-no-sepa-message').addClass('visually-hidden');
                }

                if (error) {
                    const message = errorMessage || error.errorText;
                    const sanitizeMessage = message.includes('(') ? message.split('(')[0] : message;
                    pushCheckoutErrors('checkout', sanitizeMessage);
                    return;
                }

                if (gtmProductsNew && gtmRemovedProducts) {
                    pushRemovedProductFromCart(JSON.parse(gtmRemovedProducts));
                    pushSelectedAddOnData(JSON.parse(gtmProductsNew));
                    $('.js-data-checkout-stage').data('gtm-membership-products', gtmProductsNew);
                }

                $wrapper.find('input[type=radio]').removeAttr('checked');
                $target.find('input').attr('checked', 'checked');
                $target.find('.js-price').html(cart.totals.subTotal);

                $target.closest('.js-payment-schedule').find('.pre-promo-price').html(
                    `<div class="pre-promo-price">
                        ${subscriptionBasePrice}
                    </div>`
                );

                $target.closest('.js-payment-schedule').find('.js-sub-price').html(
                    `<span class="js-sub-price">${subscriptionBasePrice}</span>`
                );

                $target.closest('.js-payment-schedule').find('.js-price').html(
                    `<span class="card__price">
                        <span>${allRecurringCosts}</span>
                    </span>`
                );

                $target.find('.js-price').html(() => {
                    $('.js-discounted-text-content').empty();

                    if (paymentInterval === '52weeks') {
                        $('#input-payment-schedule-52weeks').prop('checked', true);
                        $('#input-payment-schedule-fourWeeks').prop('checked', false);
                    } else {
                        $('#input-payment-schedule-52weeks').prop('checked', false);
                        $('#input-payment-schedule-fourWeeks').prop('checked', true);
                    }

                    if (addOnsHtml) {
                        $('.js-checkout-navigation__addons-container').empty().append(addOnsHtml);
                    }

                    if (cart.isPlanAdjusted && cart.periodPromoDiscount.available) {
                        $('.js-discounted-text-content').html(cart.periodPromoDiscountMsg);
                    }

                    if (cart.isFoundingMemberPromoTextInclude) {
                        if (paymentInterval === '52weeks') {
                            $('.js-founding-year').html(cart.foundingPromoMsg);
                        } else {
                            $('.js-founding-four-week').html(cart.foundingPromoMsg);
                        }
                    }

                    if (cart.isPlanAdjusted && cart.periodPromoDiscount.available) {
                        return `<span class="card__price">
                                    <span class="price-wrap">
                                        <span class="strike-through-price">
                                            ${subscriptionBasePrice}
                                        </span>
                                        <span class="discounted-price">
                                            ${allRecurringCosts}
                                        </span>
                                    </span>
                                </span>`;
                    }

                    return `<span class="card__price">
                                <span>${allRecurringCosts}</span>
                            </span>`;
                });

                if (paymentInterval === '52weeks' && extraYearlyPaymentPromo.text) {
                    $wrapper.find('.js-extra-promo-52weeks').removeClass('visually-hidden');
                    $wrapper.find('.js-extra-promo-fourWeeks').addClass('visually-hidden');
                    $wrapper.find('.js-extra-promo-52weeks').html(
                        `<span class="old-price">
                            &euro;${extraYearlyPaymentPromo.stPrice}
                        </span>
                        <div class="subscription-promo">
                            ${extraYearlyPaymentPromo.percentage}${extraYearlyPaymentPromo.text}
                        </div>`
                    );

                    if (paymentInterval === '52weeks' && extraYearlyPaymentPromo.text) {
                        $wrapper.find('.js-extra-promo-52weeks').removeClass('visually-hidden');
                        $wrapper.find('.js-extra-promo-fourWeeks').addClass('visually-hidden');
                        $wrapper.find('.js-extra-promo-52weeks').html(
                            `<span class="old-price">
                                &euro;${extraYearlyPaymentPromo.stPrice}
                            </span>
                            <div class="subscription-promo">
                                ${extraYearlyPaymentPromo.percentage}${extraYearlyPaymentPromo.text}
                            </div>`
                        );
                    } else {
                        $wrapper.find('.js-extra-promo-52weeks').addClass('visually-hidden');
                        $wrapper.find('.js-extra-promo-fourWeeks').addClass('visually-hidden');
                    }
                }

                if (adyenSessionData) {
                    $('.js-order-id-input').val(adyenSessionData.orderId);
                    $('.js-people-id-input').val(adyenSessionData.peopleId);
                }

                if (miniCartHtml) {
                    const $paymentSummary = $('.js-payment-summary-new');
                    const $checkoutOrderSummary = $('.js-checkout-order-summary');
                    const $checkoutOrderSummaryStatic = $paymentSummary.find('.checkout-order-summary-static');
                    $checkoutOrderSummary.empty().append(miniCartHtml);
                    $checkoutOrderSummaryStatic.empty().append(miniCartHtml);
                    initStep5SummaryAccordion();
                    initStep5bindOrderSummaryLowEntryBarrierPopup();
                    adyenDropin();
                }

                toggleOneCentPromotionContent(paymentInterval, isOneCentPromotion);
            },
            error: () => {
                $wrapper.find('.js-payment-interval-error').removeClass('visually-hidden');
            },
        });
    });
}
