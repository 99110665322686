import { appendToUrl, scrollLock } from '../util';

const STORES_MAX = 4;
const MOBILE_WIDTH = 768;
const markers = [];

const getSelectedFilters = () => $('.js-filter-button.selected').map((i, filter) => $(filter).attr('name')).get();

const postalCodeStore = pid => {
    if (!pid) {
        return false;
    }

    const $storesPagination = $('.js-stores-pagination');
    const storesElements = $storesPagination.children().toArray();

    return storesElements.length === 1 && storesElements.every(store => {
        const selectButton = $(store).find('.js-select-club-new-checkout');
        return selectButton.attr('data-pid') === pid;
    });
};

const promotionLabelTooltip = () => {
    $(document).on({
        mouseenter: e => {
            $(e.currentTarget).children().addClass('show');
        },
        mouseleave: e => {
            $(e.currentTarget).children().removeClass('show');
        },
        click: e => {
            if ($(window).width() < MOBILE_WIDTH) {
                e.preventDefault();
                $(e.currentTarget).children().toggleClass('show');
            }
        },
    }, '.js-tooltip-icon');
};

const closeToolTip = () => {
    $('body').find('.tt-modal').removeClass('show');
    $('body').removeClass('tt-popup-overlay');
    scrollLock('off');
    setTimeout(() => {
        $('.page').find('.tt-modal, .js-tt-ms-overlay').remove('.tt-modal, .js-tt-ms-overlay');
    }, 300);
};

const infoTooltipModal = (ani) => {
    let aniClass = '';

    if (ani === 'from-side') {
        aniClass = 'from-side';
    }

    // new checkout tooltip (also used outside of checkout)
    // Just in case of double binding:
    $('body').off('click', '.js-tooltip-modal-icon');
    $('body').on({
        click: e => {
            const target = e.currentTarget;

            if ($(target).hasClass('tooltip-modal-icon--disabled')) {
                return;
            }
            const ttContent = target.innerHTML;

            // check for tooltip-modal
            if ($('.tt-modal').length === 1) {
                if (aniClass !== '') {
                    $('.tt-modal').addClass(aniClass);
                }
                // if it exists just replace the content
                $('.tt-modal .tooltip-content').replaceWith(ttContent);
                $('.tt-modal').addClass('show');
                $('body').addClass('tt-popup-overlay');
                scrollLock('on');
            } else {
                // create modal
                $('.page').append(`<div class="tt-modal ${aniClass}"><span class="modal-close js-tt-ms-modal-close"></span><div class="tooltip-content"></div></div><div class="tt-overlay js-tt-ms-overlay"></div>`);
                $('.tt-modal .tooltip-content').replaceWith(ttContent);
                // add small timeout for animation purposes
                setTimeout(() => {
                    $('.tt-modal').addClass('show');
                    $('body').addClass('tt-popup-overlay');
                    scrollLock('on');
                }, 10);
            }
        },
    }, '.js-tooltip-modal-icon');

    $('body').on('click', e => {
        const $target = $(e.target);

        if ($target.is('.js-tt-ms-modal-close') || $target.is('.js-tt-ms-overlay')) {
            closeToolTip();
        }
    });

    $(document).on('keydown', (event) => {
        if (event.key === 'Escape') {
            closeToolTip();
        }
    });
};

const getStoreHtmlPopup = store => {
    let popup = `
    <div class="panel-modal-content">
        <div class="intro">
            <h3 data-cy="${store.name}" class="title club-detail">${store.address1}</h3>
            <div class="distance-location">
                ${store.distanceWithUnit ? `<span class="distance">${store.distanceWithUnit}</span>` : ''}
                <span class="postalcode">${store.postalCode}</span>
                <span class="city">${store.city}</span>
            </div>
            <div class="label-wrap">`;

    if (store.preSalePromotionDataAvailable) {
        popup += `
                <div class="discount-label discount-label--promotion">
                    <div class="discount-label__container">
                        <span class="discount-label__text">
                            ${window.resources['promo.membership.opening.title']}
                            <span class="discount-label__text-regular">${window.resources['promo.membership.opening.title.two']}</span>
                        </span>
                        <div class="discount-label__tooltip-icon js-tooltip-icon">
                            <div class="discount-label__tooltip-popup js-tooltip-popup">
                                <p class="discount-label__tooltip-popup-paragraph">${window.resources['promo.opening.details']}</p>
                                <span class="discount-label__tooltip-popup-text">${window.resources['remark.founding.members']}</span>
                            </div>
                        </div>
                    </div>
                </div>`;
    }

    if (store.status === 'PRESALE') {
        popup += `<div class="new-label">${window.resources['promo.new.club.title']}</div>`;
    }

    popup += `
            </div>
        </div>
        <ul class="club-usps">`;

    if (store.sortedFacilities) {
        store.sortedFacilities.forEach(facility => {
            popup += `<li class="${facility.class}">${facility.name}</li>`;
        });
    }

    popup += `
        </ul>
        <div class="facilities">`;

    if (store.facilitiesArray && store.facilitiesArray.facilities) {
        store.facilitiesArray.facilities.forEach(facilityData => {
            popup += `
                <div class="checkout-navigation__tab-content-feature">
                    <span class="checkout-navigation__tab-content-${facilityData.class || ''}-icon" aria-hidden="true"></span>
                    <span class="checkout-navigation__tab-content-feature-title">
                        ${facilityData.label || ''}
                    </span>
                </div>`;
        });
    }

    popup += `
        </div>
        <div class="opening-hours">`;

    if (store.workingHours) {
        Object.values(store.workingHours).forEach(line => {
            popup += `<div>${line}</div>`;
        });
    }

    popup += `
        </div>
        <div class="modal-button-wrap">
            <a
                href="javascript:;"
                class="btn btn--secondary js-select-club-new-checkout"
                data-new-checkout="true"
                data-pid="${store.productID}"
                data-name="${store.name}"
                data-cy="select-club-bttn"
                data-city="${store.city}"
                data-address1="${store.address1}"
                data-address2="${store.address2}"
                data-distance="${store.distanceWithUnit}">
                    ${window.resources['search.button.select']}
            </a>
        </div>
        </div>`;

    return popup;
};

const selectPointer = e => {
    $('.checkout-navigation .mapboxgl-canvas-container div.purple').removeClass('purple');
    $(e.currentTarget).addClass('purple');
};

const customPointers = () => {
    $('.checkout-navigation .mapboxgl-interactive div').each((i, pointer) => {
        const $pointer = $(pointer);
        $pointer.addClass('no-image');
        $pointer.off('click', selectPointer);
        $pointer.on('click', selectPointer);
    });
};

const showMapboxMarkers = stores => {
    if (typeof mapboxgl !== 'object') { return; } // eslint-disable-line no-undef

    markers.forEach(marker => marker.remove());
    const bbox = new mapboxgl.LngLatBounds(); // eslint-disable-line no-undef

    stores.forEach(store => {
        const htmlPopup = getStoreHtmlPopup(store);
        const popup = new mapboxgl.Popup().setHTML(htmlPopup); // eslint-disable-line no-undef
        const element = document.createElement('div');

        element.addEventListener('click', () => {
            window.mapboxMap.flyTo({
                center: [store.longitude, store.latitude],
                speed: 0.3,
            });
            $('.map-modal-veil').addClass('active');
            // flyTo has no call back, this works as well:
            setTimeout(() => {
                $('body').addClass('no-scroll');
            }, 1);
        });

        const marker = new mapboxgl.Marker(element) // eslint-disable-line no-undef
            .setLngLat([store.longitude, store.latitude])
            .setPopup(popup)
            .addTo(window.mapboxMap);

        markers.push(marker);
        bbox.extend([store.longitude, store.latitude]);
    });

    window.mapboxMap.fitBounds(bbox, { padding: 50 });
    customPointers();
};

const handleMap = stores => {
    const $mapboxWrapper = $('.js-mapbox-map-wrapper');
    const $storesPagination = $('.js-stores-pagination');
    const $toggleMapButton = $('.js-toggle-button-map');
    const isMapActive = $mapboxWrapper.hasClass('mapboxgl-map');
    const isMapView = $toggleMapButton.hasClass('active');
    const hasResults = stores && stores.length > 0;

    if (isMapView && hasResults) {
        $mapboxWrapper.show();
        $storesPagination.hide();

        if (window.mapboxMap) {
            window.mapboxMap.resize();
        }
    } else if (isMapView) {
        $mapboxWrapper.hide();
        $storesPagination.show();
    }

    if (isMapActive && hasResults) {
        showMapboxMarkers(stores);
    }
};

const closeMapModal = () => {
    // injected modal content and close btn
    $(document).on('click', '.mapboxgl-popup-close-button', () => {
        $('.map-modal-veil').removeClass('active');
        $('body').removeClass('no-scroll');
    });

    // clik the backdrop and close all modals inside the map
    $(document).on('click', '.map-modal-veil', () => {
        $('.map-modal-veil').removeClass('active');
        $('body').removeClass('no-scroll');
        // if we return from future steps and there is still a modal open
        if ($('.mapboxgl-popup').length > 0) {
            $('.mapboxgl-popup-close-button').trigger('click');
        }
    });
};

/**
 * Handles search stores AJAX response
 * @param {Object} data - Response data
 */
const updateStoresResults = data => {
    const $storesPaginationLinkContainer = $('.js-stores-pagination-link-container');
    const $storesPagination = $('.js-stores-pagination');
    const $storesResultMessage = $('.js-stores-result-message');
    const $toggleListButton = $('.js-toggle-button-list');
    const clubfinder = $('input[name=clubfinder]').val();
    $storesPaginationLinkContainer.attr('data-start', STORES_MAX);

    const stores = data.mapStores && data.mapStores.clusters ? data.mapStores.clusters : [];
    const storesResultsHtml = data.storesResultsHtml || '';
    const storesResultsMessageHtml = data.storesResultsMessageHtml || '';
    const hasResults = stores.length > 0;
    const showStoresPaginationLink = stores.length > STORES_MAX && $toggleListButton.hasClass('active');

    handleMap(stores);
    $storesResultMessage.html(storesResultsMessageHtml);
    $storesPagination.html(storesResultsHtml);

    if (showStoresPaginationLink) {
        $storesPaginationLinkContainer.removeClass('display-none');
    } else {
        $storesPaginationLinkContainer.addClass('display-none');
    }

    if (clubfinder) {
        $(document).trigger('clubFinderResults', [hasResults]);
    }

    const storesElementsCount = $storesPagination.children().length;
    $storesPaginationLinkContainer.attr('data-show-stores-pagination-link', stores.length > storesElementsCount);
};

/**
 * Performs search stores AJAX request
 */
const searchStores = () => {
    const $form = $('.input-wrapper');
    const $searchInput = $('input[name=searchterm]');
    const action = $form.attr('action');
    const url = $form.attr('data-url');
    const searchterm = $searchInput.val();
    const filters = getSelectedFilters();
    const searchParams = new URLSearchParams(window.location.search);
    const searchtermParameter = searchParams.get('searchterm');
    const latitude = searchParams.get('latitude');
    const longitude = searchParams.get('longitude');
    const isLocation = searchParams.get('location') === 'true' && searchtermParameter === searchterm;
    const urlParameters = {};

    if (searchterm) {
        urlParameters.searchterm = searchterm;
    }
    if (filters) {
        urlParameters.filters = filters;
    }
    if (latitude) {
        urlParameters.latitude = latitude;
    }
    if (longitude) {
        urlParameters.longitude = longitude;
    }
    if (isLocation) {
        urlParameters.location = isLocation;
    }

    history.replaceState(null, null, appendToUrl(url, urlParameters));

    urlParameters.checkout = true;
    const searchAction = appendToUrl(action, urlParameters);

    $.ajax({
        url: searchAction,
        type: $form.attr('method'),
        dataType: 'json',
        success: data => updateStoresResults(data),
    });
};

/**
 * Initilize browser geolocation prompt on the checkout step 1
 */
const checkoutLocation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const lat = searchParams.get('latitude');
    const long = searchParams.get('longitude');

    if (lat && long) {
        return;
    }

    /**
     * Handles the success scenario of the geolocation request made on the checkout step 1
     * @param {Object} position - The position object returned by the browser
     */
    const callback = position => {
        const $form = $('.input-wrapper');
        const $locationUrl = $('.js-location-address-url');
        const $searchInput = $('input[name=searchterm]');
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const formUrl = $form.attr('data-url');
        const url = `${$locationUrl.val()}?latitude=${latitude}&longitude=${longitude}&checkout=true`;

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url,
            success: response => {
                const address = response && response.address;
                const storesObject = response && response.storesObject;

                if (address) {
                    $searchInput.val(address);
                    history.replaceState(null, null, appendToUrl(formUrl, {
                        searchterm: address,
                        latitude,
                        longitude,
                        location: true,
                    }));
                }

                if (storesObject) {
                    updateStoresResults(storesObject);
                }
            },
        });
    };

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(callback);
    }
};

export {
    getSelectedFilters,
    promotionLabelTooltip,
    infoTooltipModal,
    showMapboxMarkers,
    searchStores,
    closeMapModal,
    postalCodeStore,
    checkoutLocation,
};
