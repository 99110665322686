/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable radix */

import Swiper from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules/index.mjs';
import { equalizeElements } from './checkout/membershipHelpers';

const swiperClass = '.js-m-swiper';

// Swiper for the memberships in step 2 of the checkout on mobile
// the membershipswiper in checkout step 2 is aparently reused with modifyOptionsForUspTilesSlider as well
const MembershipTiles = (() => {
    let membershipSwiper;
    function handleMembershipSwiper() {
        if (!$(swiperClass).length > 0) {
            // stop if no swipers or single slide swipers are on the page
            return;
        }

        const slideNumber = $(swiperClass).find('.swiper-slide').length;

        let options = {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, A11y],
            speed: 500,
            loop: false,
            spaceBetween: 8,
            loopedSlides: slideNumber,
            slidesPerView: 1,
            initialSlide: 1,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination-top',
                // el: '.js-m-swiper .swiper-pagination', << only single pagination now, so this one in checkout, and the other in USP tiles
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-m-swiper .arrow-next',
                prevEl: '.js-m-swiper .arrow-prev',
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
            on: {
                slideChange() {
                    if (typeof membershipSwiper !== 'undefined') {
                        // eslint-disable-next-line no-use-before-define
                        setSwipeText();
                        // eslint-disable-next-line no-use-before-define
                        slideChangePush();
                    }
                },
            },
        };

        if ($('.js-usp-tile-swiper').length) {
            options = modifyOptionsForUspTilesSlider(options);
        }

        //  if we already have one, lets wipe it
        if (typeof membershipSwiper !== 'undefined') {
            MembershipTiles.destroy();
        }
        if (typeof membershipSwiper === 'undefined' && slideNumber > 1) {
            membershipSwiper = new Swiper(swiperClass, options);

            // auto swipe to highlighted membership on start:
            // eslint-disable-next-line func-names
            $('.js-m-swiper .swiper-slide').each(function (e) {
                if ($(this).hasClass('highlighted')) {
                    membershipSwiper.slideTo(e);
                }
            });

            // eslint-disable-next-line no-use-before-define
            setSwipeText();
        }
    }

    // Datalayer pushes (clicks or swipes + the membership they ended up on)
    // clicks track both arrows and bullets
    function slideChangePush() {
        // eslint-disable-next-line func-names
        const slideIdArray = $('.js-m-swiper .swiper-slide').map(function () {
            return $(this).attr('data-name');
        }).get();

        const goToMembership = slideIdArray[membershipSwiper.activeIndex];
        const startX = membershipSwiper.touches.startX;
        const currentX = membershipSwiper.touches.currentX;
        let eventType = 'click';
        if (currentX !== startX) {
            eventType = 'swipe';
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'navigation',
            type: 'memberships',
            navigation_method: eventType, // swipe or click
            membership: goToMembership, // premium, comfort, all-in
        });
    }

    // set text labels for left and right arrow in the swiper
    function setSwipeText() {
        //  fetch data-name from all tiles
        // eslint-disable-next-line func-names
        const slideIdArray = $('.js-m-swiper .swiper-slide').map(function () {
            return $(this).attr('data-name');
        }).get();

        let PrevText = '';
        let NextText = '';

        if (membershipSwiper.activeIndex === 0) {
            // we are in first position
            NextText = slideIdArray[membershipSwiper.activeIndex + 1];
        } else if (membershipSwiper.activeIndex === (slideIdArray.length - 1)) {
            // we are in last position
            PrevText = slideIdArray[membershipSwiper.activeIndex - 1];
        } else {
            PrevText = slideIdArray[membershipSwiper.activeIndex - 1];
            NextText = slideIdArray[membershipSwiper.activeIndex + 1];
        }
        $('.js-m-swiper .arrow-prev').text(PrevText);
        $('.js-m-swiper .arrow-next').text(NextText);
    }

    const destroyParams = {
        cleanStyles: true,
    };

    function destroyMembershipSwiper() {
        if (typeof membershipSwiper !== 'undefined') {
            membershipSwiper.destroy(destroyParams);
            membershipSwiper = undefined;
        }
    }

    function modifyOptionsForUspTilesSlider(options) {
        // the mebershipswiper in checkout step 2 is aparently reused with these settings
        options.spaceBetween = 24;
        options.initialSlide = 0;
        options.pagination.el = '.js-m-swiper .swiper-pagination';

        if ($(window).width() > 600 && $(window).width() < 901) { // tablet portrait
            options.spaceBetween = 90;
            options.slidesPerView = 2;
        }

        return options;
    }

    return {
        init() {
            handleMembershipSwiper();
        },
        destroy() {
            destroyMembershipSwiper();
        },
    };
})();

const equalizeTiles = () => {
    // Equalize heights for headers
    equalizeElements('.membership-block-header');

    // Equalize heights for footers
    equalizeElements('.membership-block-footer');
};

export default MembershipTiles;
export { MembershipTiles, equalizeTiles };
